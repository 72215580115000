.chartTitle:hover .maxIcon{
  opacity: 1 !important;
}

.maxIcon {
opacity: 0 !important;
float: right;
}

rect.disabled {
  fill: transparent !important;
}

.badgemainamount {
  font-size: 20px;
  color:'#19181A';
  opacity: '0.8';
  font-weight: bold;
}
